/*
*
* Validación de formulario
*
*/

function showMessage(message, cssClass, inputID)
{
  const IDInput = document.querySelector(inputID);
  const spanErrorMessage = document.createElement('span');

  IDInput.style.boder = "1px solid #df0101";
  spanErrorMessage.className = `alert alert-${cssClass} mt-2 d-block`;
  spanErrorMessage.appendChild(document.createTextNode(message));
  // mostrar en el dom
  const cajaErrores = document.querySelector('#errores');
  // console.log(spanErrorMessage);
  cajaErrores.appendChild(spanErrorMessage);

  setTimeout(function(){
    // IDInput.classList.remove('border', 'border-danger');
    document.querySelector('.alert').remove();
  }, 3000);
}
function successMessage(message)
{
  const spanErrorMessage = document.createElement('span');

  spanErrorMessage.className = `alert alert-success mt-2 d-block`;
  spanErrorMessage.appendChild(document.createTextNode(message));
  // mostrar en el dom
  const cajaErrores = document.querySelector('#errores');
  // console.log(spanErrorMessage);
  cajaErrores.appendChild(spanErrorMessage);

  setTimeout(function(){
    // IDInput.classList.remove('border', 'border-danger');
    document.querySelector('.alert').remove();
  }, 3500);
}

function isChecked(campoValor, inputID)
{
  if(campoValor == false)
  {
    showMessage('Tiene que aceptar los terminos y condiciones para enviar el formulario', 'danger', inputID);
    return false;
  }
  else
  {
    return true;
  }
}

function isText(campoValor, inputID)
{
  const pattern = new RegExp('^[A-Z]+$', 'i');
  if(campoValor === "" || campoValor === null)
  {
      showMessage('No puede dejar el campo en blanco', 'danger', inputID);
      return false;
  }
  else if(campoValor.length <= 1){
      showMessage('Debe contener minimo 2 caracteres.', 'danger', inputID);
      return false;
  }
  else if(campoValor.length >= 50){
      showMessage('Debe contener máximo 50 caracteres.', 'danger', inputID);
      return false;
  }
  else
  {
    return true;
  }

}

function isDate(campoValor, inputID)
{
    if(campoValor === "" || campoValor === null)
    {
        showMessage('Ingrese una fecha por favor.', 'danger', inputID);
        return false;
    }
    else{
        return true;
    }
}
function isPhone(campoValor, inputID)
{
    const expRegPhone = new RegExp("^[1-9][0-9]+$");
    if(campoValor === "" || campoValor === null)
    {
      showMessage('El campo de telefono no puede estar vacio.', 'danger', inputID);
      return false;
    }
    else if(!expRegPhone.test(campoValor)){
      showMessage('Formato de telefono invalido', 'danger', inputID);
      return false;
    }else if(isNaN(campoValor)){
      showMessage('Solo se permiten números.', 'danger', inputID);
      return false;
    }else{
      return true;
    }

}
function isMail(campoValor, inputID)
{
    const expRegMail = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$", 'i');
    if(campoValor === "" || campoValor === null)
    {
        showMessage('El campo correo no puede estar vacio.', 'danger', inputID);
        return false;
    }
    else if(!expRegMail.test(campoValor)){
        showMessage('Formato de correo invalido', 'danger', inputID);
        return false;
    }else{
        return true;
    }
}

export { showMessage, successMessage, isChecked, isText, isDate, isPhone, isMail };