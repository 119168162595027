$('[data-fancybox="gallery"]').fancybox({
	// Options will go here
});

/*
*
* Tooltip
*
*/

$('#carouselIndicators').on('slide.bs.carousel', function () {
  $('.carousel-caption h5').addClass('animated fadeInDown fast');
  $('.carousel-caption p').addClass('animated fadeInUp delay-1s');
  $('.carousel-caption .btn-cta').addClass('animated fadeInUp delay-2s');
})


$('.owl-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive:{
        0:{
            items:3
        },
        600:{
            items:4
        },
        1000:{
            items:6
        }
    }
})


window.onload = function()
{
  $('#loading').fadeOut();
  $('body').removeClass('hidden');
}
