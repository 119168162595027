import { showMessage, successMessage, isChecked, isText, isDate, isPhone, isMail } from './formulario/validation';


$('#btn-submit').on('click', function(e){
  e.preventDefault();

  let fullNameField = $('#fullNombre').val();
  let emailField = $('#email').val();
  let phoneField = $('#telefono').val();
  let messageField = $('#mensaje').val();
  let termsField = $('#terms').prop('checked');

  let btnSubmit = document.querySelector('#btn-submit');

  // Se validan que todos los inputs esten correctos al mandar formulario
  if(!isText(fullNameField, '#fullNombre')){
    document.querySelector('#fullNombre').focus();
  }else if(!isMail(emailField, '#email')){
    document.querySelector('#email').focus();
  }else if(!isPhone(phoneField, '#telefono')){
    document.querySelector('#telefono').focus();
  }else if(!isChecked(termsField, '#terms')){
    document.querySelector('#terms').focus();
  }else{
    btnSubmit.innerHTML = '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>';
    btnSubmit.disabled = true;
    btnSubmit.classList.add('btn-disabled');
    setTimeout(() => {

      $.ajax({
        url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeZ6x7V06cY-bhTIGqpH6h4rN1SjGfSQRL6i1gVif5hO9mRGw/formResponse',
        data: {
          "entry.1902143898": fullNameField, 
          "entry.1595289290": emailField, 
          "entry.798355513": phoneField, 
          "entry.1216942882": messageField
        },
        type: 'POST',
        dataType: 'xml',
        success: function(d)
        {

        },
        error: function(x, y, z)
        {
          console.log('no se ha podido hacer nada');
        }
      });
      successMessage('Mensaje enviado')
      btnSubmit.innerHTML = 'Solicitar Asesoria <i class="ml-2 far fa-envelope-open"></i>';
      btnSubmit.disabled = false;
      btnSubmit.classList.remove('btn-disabled');
      $('#form-datos')[0].reset();
    }, 1000);

  }


});



