import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

import 'bootstrap';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';


require('./scss/main.scss');

// librerias externas

var jQueryBridget = require('jquery-bridget');

require("@fancyapps/fancybox");

//  own scripts
require('./js/base.js');
require('./js/form-submit.js');
require('./js/hero-slider.js');
require('./js/owlcarousel-options.js');


// var Isotope = require('isotope-layout');
// jQueryBridget( 'isotope', Isotope, $ );

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init();


